import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsletterRibbon from '../components/NewsletterRibbon';

// import "./layout.css"
import BackgroundImage from 'gatsby-background-image'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  productionsWrapper: {
    paddingBottom: "1em",
    maxWidth: "85rem",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column"
  },
  showsWrapper: {
    paddingBottom: "1em",
    maxWidth: "60rem",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column"
  },
  jumbo: {
    height: "100vh",
    backgroundAttachment: "fixed",
    backgroundPosition: "right"
  },
  dropTop: {
    paddingTop: "100%"
  }
}));

export const query = graphql`
    query {
      jumbo: file(relativePath: { eq: "beulah_creek_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      frankenstein: file(relativePath: { eq: "FrankensteinPromo-65.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wine: file(relativePath: { eq: "wine.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      podplays: file(relativePath: { eq: "podplays.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cityLights: file(relativePath: { eq: "cityLights.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      artsAtlanta: file(relativePath: { eq: "artsAtlanta.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      nichole: file(relativePath: { eq: "nichole.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      elizabethVictor: file(relativePath: { eq: "victor-elizabeth-optimized.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `

const IndexPage = ({ data }) => {

  const classes = useStyles();

  return (
  <Layout>
    <SEO title="Home" />

    <BackgroundImage 
        Tag="section"
        className={classes.jumbo}
        fluid={data.jumbo.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        >
          <div class="jumbo-inner-wrapper-home">
              <div>
                <h1 class="jumbo-title-wine">
                    Found Stages
                </h1>
                <h5 class="subtitle-wine">New Plays, No Walls</h5>
                {/* <br></br><br></br>
                Oct 1st & 2nd
                </h5>
                
                <div class="wine-buttons">
                  <a title="Get Tickets" id="get-tickets-main" href="https://www.eventbrite.com/e/cassies-ballad-at-elevate-festival-tickets-418386855347" class="button button--bigger blackButton--border">
                    Get Tickets
                  </a>
                  <a title="Find out more" href="/cassies-ballad" class="button button--bigger blackButton--border">
                  Find out More
                  </a>
              </div> */}
              </div>
          </div>
        </BackgroundImage>

    <div className={classes.root}>
      {/* <Grid container spacing={3} className={classes.showsWrapper}> */}
      <div className={classes.showsWrapper}>
        <div class="news-title-wrapper">
          <div class="productions-title">
              In the News
          </div>
        </div>

        <div class="show-cards-wrapper">
          <div class="wrapper">
          <div class="logo-wrapper">  
            <div class="logo-1">
            </div>
          </div>
              <div class="top card-4">
                {/* <BackgroundImage 
                      Tag="div"
                      className={classes.dropTop}
                      fluid={data.cityLights.childImageSharp.fluid}
                      backgroundColor={`#040e18`}
                      >
                  </BackgroundImage> */}
                </div>
                  <div class="bottom">
                      <h5 class="card-title centered-text">City Lights: Frankenstein’s Funeral Puts A New Twist On A Classic Tale</h5>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                      <p class="production-card__locations">GLASGOW</p> */}
                      <div class="links-container">
                              <a title="Find out more about Them!" target="_blank" rel="noopener noreferrer" href="https://www.wabe.org/frankensteins-funeral-puts-a-new-twist-on-a-classic-tale/" class="button button--medium button--border">Find out more</a>
                    </div>
                </div>
            </div>
            <div class="wrapper">
            <div class="logo-wrapper-2">  
              <div class="logo-2">
              </div>
            </div>
                <div class="top card-5">
                  {/* <BackgroundImage 
                      Tag="div"
                      className={classes.dropTop}
                      fluid={data.artsAtlanta.childImageSharp.fluid}
                      backgroundColor={`#040e18`}
                      >
                  </BackgroundImage> */}
                </div>
                    <div class="bottom">
                        <h5 class="card-title centered-text">Review: “Frankenstein’s Funeral” most alive in Mary Shelley’s mind, Monster’s movement</h5>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                        <p class="production-card__locations">GLASGOW</p> */}
                        <div class="links-container">
                                <a title="Find out more!" target="_blank" rel="noopener noreferrer" href="https://www.artsatl.org/review-frankensteins-funeral-most-alive-in-mary-shelleys-mind-monsters-movement/" class="button button--medium button--border">Find out more</a>
                    </div>
                </div>
            </div>
            <div class="wrapper">
            <div class="logo-wrapper-3">  
              <div class="logo-3">
              </div>
            </div>
                <div class="top card-6">
                  {/* <BackgroundImage 
                      Tag="div"
                      className={classes.dropTop}
                      fluid={data.nichole.childImageSharp.fluid}
                      backgroundColor={`#040e18`}
                      >
                  </BackgroundImage> */}
                </div>
                    <div class="bottom">
                        <h5 class="card-title centered-text">Atlanta fosters female voices</h5>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span>
                        <p class="production-card__locations">GLASGOW</p> */}
                        <div class="links-container">
                                <a title="Find out more about Them!" target="_blank" rel="noopener noreferrer" href="https://www.ajc.com/entertainment/arts--theater/women-run-theater-companies-push-the-bounds-the-artform/otjHwlzc3Gkxy59wCw6mQI/" class="button button--medium button--border">Find out more</a>
                    </div>
                </div>
            </div>
        </div>
        </div>           
      {/* </Grid> */}
      <NewsletterRibbon />
    </div>
  </Layout>
  )
}

export default IndexPage
